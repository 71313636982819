/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

// ES5 way
// exports.onClientEntry = () => {
// ES6 way


export const onClientEntry = () => {

  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (!(`IntersectionObserver` in window)) {
    import(`intersection-observer`)
    console.log(`# IntersectionObserver is polyfilled!`)
  }

  if (!(`URLSearchParams` in window)) {
    window.URLSearchParams = function (searchString) {
      var self = this;
      self.searchString = searchString;
      self.get = function (name) {
        var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(self.searchString);
        if (results == null) {
          return null;
        }
        else {
          return decodeURI(results[1]) || 0;
        }
      };
    }
    console.log(`# URLSearchParams is polyfilled!`)
  }



}